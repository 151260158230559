import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/common/Navbar";
import Footer from "../Components/common/Footer";
import MainBanner from "../Components/Home/MainBanner";
import Media from "../Components/Home/Media";
import About from "../Components/Home/About";
import StayUpdated from "../Components/Home/StayUpdated";
import ServiceSection from "../Components/Home/ServiceSection";
import Career from "../Components/Home/Career";
import Blog from "../Components/Home/Blog";
import Judgment from "../Components/Home/Judgment";
import Popup from "../Components/common/Popup";
import { ImCross } from "react-icons/im";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
const Home = () => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);
  return (
    <div className="">
      <Navbar />
      <MainBanner />
      <div className="grid md:grid-cols-3 md:px-16 px-6 gap-8 md:py-20 py-16">
        <ServiceSection
          title="Team"
          pera="The diversity of our people and services give us far-reaching expertise and experience, consolidated in one firm to deliver the most impactful advice possible."
          img="/assets/images/teamOne.png"
          text="Read More"
          link="/team"
        />
        <ServiceSection
          title="Update"
          pera="Explore a diverse range of topics through our knowledge section, designed to enlighten, inspire, and get updated with the industry trends."
          img="/assets/images/UpdatedImg/Sectors.jpg"
          text="Read More"
          link="/update-and-analysis"
        />
        <ServiceSection
          title="Practices"
          pera="A full service law firm, our teams work seamlessly together across our practice areas and offices to produce innovative solutions for our clients."
          img="/assets/images/UpdatedImg/Practices.jpg"
          text="Read More"
          link="/practices"
        />
      </div>
      {/* <Media/> */}
      <div className="mt-10">
        <h2 className="md:text-4xl text-2xl   text-secondary text-center font-medium">
          Rankings and Recognitions
        </h2>
        <div className="h-1 w-24 bg-primary mx-auto mt-4"></div>
        <div className="max-w-3xl mx-auto grid grid-cols-2 md:grid-cols-4 justify-items-center items-center py-10 space-y-4">
        <img src="/assets/images/ap-recommended-lawyer-2024.png" alt="/" className="w-24" />
        <img src="/assets/images/Legal2024.jpeg" alt="/" className="w-24" />
        <img src="/assets/images/Chamber.jpeg" alt="/" className="w-36" />
        <img src="/assets/images/ISO.jpeg" alt="/" className="w-24 " /> 
        </div>
        <div className="max-w-2xl mx-auto grid grid-cols-2 md:grid-cols-3 justify-items-center items-center py-10 space-y-4">
        <img src="/assets/images/rank1.png" alt="/" className="w-24 pt-4" /> 
        <img src="/assets/images/rank4.jpg" alt="/" className="w-28" />
        <img src="/assets/images/IFLR2023.png" alt="/" className="w-24" />
         
        </div>
      </div>
      {/* <Blog /> */}
      <Career />
      {/* <About/> */}
      {/* <div className=" text-center">
        <h2 className="text-2xl text-secondary  font-bold md:text-4xl pt-12">
        Judgments
        </h2>
        <p className="max-w-5xl mx-auto mt-4 text-gray-600">In an industry where knowledge and experience are crucial, we deliver pre-eminent legal advice that generates great results. Browse through highlights of prominent judgments below:</p>
      </div>
      <div className="grid md:grid-cols-3 px-16 gap-8 py-10">
      <Judgment num="01" title="UAE Judgment- The scope and nature of an action to determine facts" img="https://img.freepik.com/free-photo/top-view-career-guidance-items-judges_23-2149443471.jpg?w=360&t=st=1660648623~exp=1660649223~hmac=fe194957b46c19625aed3539ccd7c45456f175d394f5cf3797ba1e60fa33f87d"/>
      <Judgment num="02" title="Qatari court judgment- The liability of an agent for failure to give effect to the lawful" img="https://img.freepik.com/free-photo/businessman-reading-contract-closeup_1098-14742.jpg?w=996&t=st=1660654452~exp=1660655052~hmac=a1bb3c5a8f62ad990f80ffa26373af4da3bf77853b005c641173c0a8aaaa5ead"/>
      <Judgment num="03" title="UAE Court judgment- COVID-19 disruption may be grounds for termination of a lease agreement" img="https://img.freepik.com/free-photo/book-library-with-open-textbook_1150-5924.jpg?w=996&t=st=1660654390~exp=1660654990~hmac=500f565f7ff16bf9f1cc3ebcf561780c66145460960157677ee22d074c7fb595"/>
      </div> */}
      {/* <StayUpdated /> */}
      <AnimatePresence>
        {show && (
          <motion.div
            key="box"
            className=" z-50 bottom-10 fixed box "
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: show ? 1 : 0, scale: show ? 1 : 0.5 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.5 } }}
          >
            <div className="relative shadow-md shadow-slate-400 rounded-md bg-[#d9d4cf] w-[90%] mx-auto border-slate-400">
              <div
                className="rounded-full bg-primary text-secondary cursor-pointer inline-block p-3 absolute right-4 -top-4 hover:scale-110 duration-500 transition hover:text-primary hover:bg-secondary"
                onClick={(e) => setShow(false)}
              >
                <ImCross className="" />
              </div>
              <div className=" ">
                <Popup />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Footer />
    </div>
  );
};

export default Home;
