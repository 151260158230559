import React from "react";
import Career from "../Home/Career";
import InnerBanner from "../common/InnerBanner";
const ContactPage = () => {
  return (
    <div>
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02479.png"
        BannerTitle="Contact Us"
        BannerText="Everything begins with communication, reach us out and get yourself a partner from the legal world to help you out in several circumstances.
        GET AP & PARTNERS ON BOARD!
         "
      />
      <div className="grid lg:grid-cols-2 sm:grid-cols-1 mx-auto my-10 md:px-12  md:space-y-0 space-y-36">
        <div className="google-map-code mx-auto md:mb-20 lg:mb-0 w-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28032.326913467452!2d77.15993089999999!3d28.568535599999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe332f96caed3f04d!2sAP%20%26%20Partners%2C%20Advocates!5e0!3m2!1sen!2sin!4v1672925198399!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="w-full h-96"
          ></iframe>
        </div>
        {/* <section
          className="py-20 px-4 lg:px-28 overflow-hidden relative flex flex-end"
          data-aos="fade-up"
          id="contact"
        > */}

        <div className="container flex flex-col">
          <div className="flex flex-col lg:flex-row lg:items-center text-slate-900 dark:text-gray-200 lg:justify-between -mx-4">
            <div className="w-100 mb-12 lg:mb-0 space-y-4  flex flex-col justify-start items-start">
              <div className=" text-justify  justify-center items-center">
                <p className="  text-secondary  lg:text-3xl text-2xl font-bold text-body-color leading-relaxed ">
                  Delhi
                </p>
                <div className="h-1 bg-primary w-100" />
                <p className="font-bold text-black w-100">
                  To contact the appropriate people or departments, please use
                  the contact information below:
                </p>
              </div>
              <div className="flex text-justify justify-center items-center">
                {/* <p className="   text-secondary lg:text-2xl text-xl font-medium text-body-color leading-relaxed ">
                  Address-
                </p> */}
                <p className="  text-secondary  font-medium items-center text-base ">
                  B-62, Paschimi Marg, Vasant Vihar, New Delhi - 110057, India
                </p>
              </div>
              <div className="flex text-justify  justify-center items-center">
                {/* <p className="   text-secondary lg:text-2xl text-xl font-medium  leading-relaxed ">
                  Email-
                </p> */}
                <p className="  font-medium   text-secondary  text-base  ">
                  <a href="mailto:contact@appartners.in">
                    {" "}
                    contact@appartners.in{" "}
                  </a>
                  {/* <br /> Ap&PARTNERS@gmail.com */}
                </p>
              </div>
              <div className="flex text-justify justify-center items-center">
                {/* <p className="   text-secondary lg:text-2xl text-xl font-medium text-body-color leading-relaxed ">
                  Phone No.-
                </p> */}
                <p className="  text-secondary  font-medium  text-base ">
                  + 91 11 4259 4444
                </p>
              </div>
              <hr style={{ width: "100%", marginLeft: 0 }} />
              {/* <div className="flex text-justify justify-center items-center">
                <p className="  text-black ">
                  For general enquiries, please contact:{" "}
                  <a
                    href="mailto:info@appartners.in"
                    className="text-secondary"
                  >
                    info@appartners.in{" "}
                  </a>
                </p>
              </div> */}
              {/* <div className="text-justify justify-center items-center">
                  <p className="text-black">
                    Questions regarding staffing can be directed to: hr@appartners.in</p>
                  <p className="text-black ">
                    Questions regarding legal recruiting can be directed to: recruiting@appartners.in</p>
                </div>
                <div className="flex text-justify justify-center items-center">
                  <p className="  text-black ">
                    To report technical problems or errors on our Web site, please contact techsupp@appartners.in
                  </p>

                </div> */}
            </div>
          </div>
        </div>

        {/* </section> */}
      </div>
      <div className="grid lg:grid-cols-2 sm:grid-cols-1 mx-auto my-10 md:px-12  md:space-y-0 space-y-36">
       
        {/* <section
          className="py-20 px-4 lg:px-28 overflow-hidden relative flex flex-end"
          data-aos="fade-up"
          id="contact"
        > */}

        <div className="container flex flex-col">
          <div className="flex flex-col lg:flex-row lg:items-center text-slate-900 dark:text-gray-200 lg:justify-between -mx-4">
            <div className="w-100 mb-12 lg:mb-0 space-y-4  flex flex-col justify-start items-start">
              <div className=" text-justify  justify-center items-center">
                <p className="  text-secondary  lg:text-3xl text-2xl font-bold text-body-color leading-relaxed ">
                  Gurugram
                </p>
                <div className="h-1 bg-primary w-100" />
                <p className="font-bold text-black w-100">
                  To contact the appropriate people or departments, please use
                  the contact information below:
                </p>
              </div>
              <div className="flex text-justify justify-center items-center">
                {/* <p className="   text-secondary lg:text-2xl text-xl font-medium text-body-color leading-relaxed ">
                  Address-
                </p> */}
                <p className="  text-secondary  font-medium items-center text-base ">
                5th Floor, Tower A, Global Gateway Tower, Sector 24, MG Road, Gurgaon, Haryana 122022, India
                </p>
              </div>
              <div className="flex text-justify  justify-center items-center">
                {/* <p className="   text-secondary lg:text-2xl text-xl font-medium  leading-relaxed ">
                  Email-
                </p> */}
                <p className="  font-medium   text-secondary  text-base  ">
                  <a href="mailto:contact@appartners.in">
                    {" "}
                    contact@appartners.in{" "}
                  </a>
                  {/* <br /> Ap&PARTNERS@gmail.com */}
                </p>
              </div>
              <div className="flex text-justify justify-center items-center">
                {/* <p className="   text-secondary lg:text-2xl text-xl font-medium text-body-color leading-relaxed ">
                  Phone No.-
                </p> */}
                <p className="  text-secondary  font-medium  text-base ">
                + 91 124 4891670
                </p>
              </div>
              <hr style={{ width: "100%", marginLeft: 0 }} />
              {/* <div className="flex text-justify justify-center items-center">
                <p className="  text-black ">
                  For general enquiries, please contact:{" "}
                  <a
                    href="mailto:info@appartners.in"
                    className="text-secondary"
                  >
                    info@appartners.in{" "}
                  </a>
                </p>
              </div> */}
              {/* <div className="text-justify justify-center items-center">
                  <p className="text-black">
                    Questions regarding staffing can be directed to: hr@appartners.in</p>
                  <p className="text-black ">
                    Questions regarding legal recruiting can be directed to: recruiting@appartners.in</p>
                </div>
                <div className="flex text-justify justify-center items-center">
                  <p className="  text-black ">
                    To report technical problems or errors on our Web site, please contact techsupp@appartners.in
                  </p>

                </div> */}
            </div>
          </div>
        </div>
        <div className="google-map-code mx-auto md:mb-20 lg:mb-0 w-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224568.15030524903!2d76.9897491!3d28.42295755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa540c9a1b4502f6b%3A0x55af8e9dcc8b0aee!2sAP%20%26%20Partners%20Advocates!5e0!3m2!1sen!2sin!4v1733731827044!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="w-full h-96"
          ></iframe>
        </div>

        {/* </section> */}
      </div>
      {/* <Career /> */}
    </div>
  );
};

export default ContactPage;
